/** @jsx jsx */
import { Fragment, FC } from 'react'
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'

import Helmet from '../../components/HelmetHelper'
import Container from '../../components/Container'
import Text, { TEXT_SIZE_SMALL } from '../../components/content/Text'
import {
	THEME_TAUPE,
	THEME_GREEN,
	THEME_WHITE,
	colors,
	media,
} from '../../constants'
import Slide, {
	SLIDE_LAYOUT_MOCK_FULL_SCREEN,
	SLIDE_LAYOUT_FULL_BLEED_IMAGE,
} from '../../components/Slide'
import {
	LayoutSplitHeading,
	LayoutIndented,
	LayoutCol2,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
} from '../../components/content/Layout'
import FullBleedImage from '../../components/content/FullBleedImage'
import { InlineLink, StandaloneLink } from '../../components/content/Link'
import { provideAccentTheme } from '../../context/AccentThemeContext'
import Highlight from '../../components/content/Highlight'

import Showcase, {
	SHOWCASE_LAYOUT_3UP,
} from '../../components/content/Showcase'
import VideoLoop, {
	VIDEO_CLIP_AUTOPLAY,
} from '../../components/content/VideoLoop'
import wazerMoods from '../../images/waze/waze-moods.gif'

import { caseStudyLinkData } from '../../workData'

const nextPage = caseStudyLinkData.waze.next

type Props = {
	accentTheme: string
}

const WazePage: FC<Props> = () => {
	const data = useStaticQuery(graphql`
		query {
			home: file(relativePath: { eq: "waze/waze-home.png" }) {
				...ImageFluidFull
			}
			screen1: file(relativePath: { eq: "waze/waze-screen-1.png" }) {
				...ImageFluidFull
			}
			screen2: file(relativePath: { eq: "waze/waze-screen-2.png" }) {
				...ImageFluidFull
			}
			screen3: file(relativePath: { eq: "waze/waze-screen-3.png" }) {
				...ImageFluidFull
			}
			graphicsCollage: file(
				relativePath: { eq: "waze/waze-graphics-collage.png" }
			) {
				...ImageFluidFull
			}
			blocks: file(relativePath: { eq: "waze/waze-block-by-block.png" }) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<Fragment>
			<Helmet>
				<title>Waze</title>
				<meta content="Waze Case Study" property="og:title" />
				<meta content="Waze Case Study" name="twitter:title" />
				<meta
					property="og:description"
					content="A new home for a bold, witty rebrand."
				/>
				<meta
					name="description"
					content="A new home for a bold, witty rebrand."
				/>
				<meta
					content="https://freeassociation.com/share/waze.png"
					property="og:image"
				/>
				<meta
					content="https://freeassociation.com/share/waze.png"
					property="twitter:image:src"
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--eyebrow">Waze</h1>
							<p className="--headline">
								A new home for a bold, witty rebrand.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutSplitHeading title="Overview">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<p>
									Waze recently overhauled their brand identity in order to
									unify their look without losing the quirkiness that customers
									love. With the overhaul complete, Waze needed help translating
									the wealth of new guidelines to a digestible format for a
									diverse community of users.
								</p>
								<p>
									Free Association partnered closely with Waze’s Brand &
									Marketing team to transform static specifications to a new,
									living home for the brand — one place where employees,
									contractors, partners, sponsors, and community members alike
									could find the latest guidance and tools to create great work.
								</p>
							</Text>
						</LayoutSplitHeading>
						<LayoutSplitHeading title="Services">
							<Text defaultParagraph={TEXT_SIZE_SMALL}>
								<ul className="--unstyled-mediumUp --col-2">
									<li>Product Leadership</li>
									<li>Strategic Planning</li>
									<li>Project Management</li>
									<li>Information Architecture</li>
									<li>Technical Strategy </li>
									<li>UX Protoyping</li>
									<li>User Experience Design</li>
									<li>UI &amp; Visual Design</li>
									<li>Interaction Prototyping</li>
									<li>Brand Systems &amp; Guidelines</li>
									<li>Web Development </li>
									<li>Design Tools</li>
								</ul>
							</Text>
						</LayoutSplitHeading>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage fluidImageData={data.home.childImageSharp.fluid} />
				</div>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
					},
				}}
			>
				<Container maxWidth="75rem">
					<Showcase
						layout={SHOWCASE_LAYOUT_3UP}
						images={[
							data.screen1.childImageSharp.fluid,
							data.screen2.childImageSharp.fluid,
							data.screen3.childImageSharp.fluid,
						]}
					/>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									Our goal with Brand Center was to make the new guidelines easy
									to understand, fun to use, and fast to update.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Content &amp; Architecture">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We architected the site to serve as a user friendly home for
										foundational guidelines, as well as a showcase for
										best-in-class creative and a resource for downloads.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Tooling">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We created a family of Starter Kits that package templates
										and assets together along with step-by-step guides to give
										creators a clear starting point for nearly any asset.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Design System">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We built a system of components and content modules to allow
										for flexibility across the site sections while maintaining
										consistency across the site.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Dynamic Details">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										We put extra love into the experience to make it “Wazey”
										— microinteractions on buttons and links, animated visual
										aids, and playful 404 pages.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide prefferedTheme={THEME_TAUPE}>
				<Container>
					<div
						css={{
							display: 'flex',
							justifyContent: 'center',
						}}
					>
						<img
							src={wazerMoods}
							css={{
								display: 'block',
								maxWidth: 444,
							}}
						/>
					</div>
				</Container>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p>
								Reading guidance is one thing, creating great designs is
								another. We built a library of templates for digital and print
								applications with embedded step-by-step directions to give
								designers a head start on all kinds of creative.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_TAUPE}
				style={{
					[media.small]: {
						paddingTop: '3.5rem',
						paddingBottom: '3.5rem',
						marginBottom: '3.5rem',
					},
				}}
			>
				<Container>
					<VideoLoop width={2852} height={2140} mode={VIDEO_CLIP_AUTOPLAY}>
						<source src="/waze-brand-center-resources-4-3.mov" />
					</VideoLoop>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage
						fluidImageData={data.graphicsCollage.childImageSharp.fluid}
					/>
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
						<LayoutIndented>
							<Text>
								<p>
									In addition to the Brand Center, we’ve collaborated with Waze
									on their core product, marketing programs, and sonic identity.
								</p>
							</Text>
						</LayoutIndented>
						<LayoutCol2 verticalCadenceSize={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
							<Highlight title="Core Product">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Strategy and design for ‘Waze Voices’, a feature in the core
										navigation app.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Marketing Programs">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Promotional videos introducing new features and user flows
										for Waze Carpool.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Sonic Identity">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Sound design for instant brand recall.
									</p>
								</Text>
							</Highlight>
							<Highlight title="Visual Identity">
								<Text defaultParagraph={TEXT_SIZE_SMALL}>
									<p className="--grey">
										Scaling the brand refresh to marketing and product
										touchpoints to evaluate and crystalize the rules.
									</p>
								</Text>
							</Highlight>
						</LayoutCol2>
					</LayoutVerticalCadence>
				</Container>
			</Slide>
			<Slide
				prefferedTheme={THEME_WHITE}
				layout={SLIDE_LAYOUT_FULL_BLEED_IMAGE}
				style={{
					[media.small]: {
						marginBottom: '3.5rem',
					},
				}}
			>
				<div
					css={{
						background: colors.taupe,
					}}
				>
					<FullBleedImage fluidImageData={data.blocks.childImageSharp.fluid} />
				</div>
			</Slide>
			<Slide>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--eyebrow">Next</p>
							<p className="--headline">
								<StandaloneLink to={nextPage.to!}>
									{nextPage.title}
								</StandaloneLink>
							</p>
							<p className="--small">
								<InlineLink to="/work">All Work</InlineLink>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</Fragment>
	)
}

export default provideAccentTheme(WazePage, THEME_GREEN)
